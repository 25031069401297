import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../../components/seo'
import { MdxPageContent } from './__generated__/MdxPageContent'
import { Container } from './styled'

const formatBody = (body: string) => {
  let newBody = body
  //  Get all "code" blocks with parent "pre" without language and set a default language
  const emptyCodes =
    body.match(/mdx\("code"([^)]+)parentName:([^)]+)"pre"([^)]+)\)/g) || []
  for (const code of emptyCodes) {
    if (!code.includes('"className"'))
      newBody = newBody.replace(
        code,
        code.replace(
          'parentName: "pre"',
          'parentName: "pre", "className": "language-text-generated"',
        ),
      )
  }

  return newBody
}

export const Page: React.FC<PageProps<MdxPageContent, unknown>> = (props) => {
  const node = props.data.allMdx.edges[0]?.node

  if (!node) return null

  const { body, frontmatter } = node

  const formattedBody = formatBody(body)

  return (
    <Container>
      <SEO title={frontmatter?.title || ''} />
      <MDXRenderer>{formattedBody}</MDXRenderer>
    </Container>
  )
}

export default Page

export const query = graphql`
  query MdxPageContent($slug: String) {
    allMdx(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`
