import styled from 'styled-components'

export const Container = styled.div`
  h2 {
    margin-top: 32px;
  }

  h3 {
    margin-top: 16px;
  }

  ul li > p {
    margin: 0;
  }
`
